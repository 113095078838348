import { Trans, useTranslation } from 'react-i18next';
import { TableColumnType, TableRowActionType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { Table } from 'component/new_design/base/Table';
import { formatLocaleDate } from 'utils/dateFormat';
import {
  Backup,
  useDeleteBackup,
  useGetBackups,
  useGetDownloadLink,
  useRestoreBackup,
} from 'api/backups';
import { Button } from '@mui/material';
import { BackupStatusCell } from './BackupStatusCell';
import DownloadIcon from 'component/new_design/icons/Download.svg?react';
import DeleteIcon from 'component/new_design/icons/Delete.svg?react';
import { downloadURL } from 'utils/download';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { useState } from 'react';
import { ManualBackupCreateDialog } from '../../SiteDetails/Backups/ManualBackupCreateDialog';
import PlusIcon from 'component/new_design/icons/Plus.svg?react';
import Server from 'component/new_design/icons/Server.svg?react';
import { ConfirmationDialog } from 'component/new_design/base/dialogs/ConfirmationDialog';
import { EmptyState } from 'component/new_design/base/EmptyState';
import { useRoleGuard } from 'component/hooks/useRoleGuard';
import { RoleGuard } from 'component/base/RoleGuard';

interface ManualBackupsTableProps {
  readonly siteId: string;
}

export function ManualBackupsTable({ siteId }: ManualBackupsTableProps) {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [idToRestore, setIdToRestore] = useState<number | null>(null);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const restoreBackup = useRestoreBackup(siteId);
  const deleteBackup = useDeleteBackup(siteId);
  const getDownloadLink = useGetDownloadLink(siteId);

  const tableState = usePaginatedListState({ urlStatePrefix: 'manual' });

  const { data, isLoading } = useGetBackups(siteId, tableState);

  const showActions = !useRoleGuard(['billing_admin'], 'block');

  const columns: TableColumnType<Backup>[] = [
    {
      label: t('label'),
      key: 'label',
    },
    {
      label: t('status'),
      key: 'status',
      renderValue: backup => <BackupStatusCell backup={backup} />,
    },
    {
      label: t('created'),
      key: 'created',
      renderValue: backup =>
        formatLocaleDate({ locale: i18n.language, value: `${backup.created}Z` }),
    },
  ];

  const rowActions: TableRowActionType<Backup>[] = showActions
    ? [
        {
          icon: <DownloadIcon />,
          label: t('download'),
          onClick: async row => {
            const result = await getDownloadLink.mutateAsync(row.task_id!);
            const link = result.data.result?.[0].download_link;
            if (link) {
              downloadURL(link, `backup-${row.label}-${row.created}.zip`);
            } else {
              enqueueSnackbar(null, { variant: 'error' });
            }
          },
        },
        {
          inline: true,
          label: t('restore'),
          onClick: row => setIdToRestore(row.id!),
        },
        {
          icon: <DeleteIcon />,
          label: t('delete'),
          onClick: row => setIdToDelete(row.id!),
          color: 'reds.500',
        },
      ]
    : [];

  const handleRestoreConfirmed = async () => {
    if (!idToRestore) {
      enqueueSnackbar(null, { variant: 'error' });
      return;
    }
    await restoreBackup.mutateAsync({
      backup_id: String(idToRestore),
      backup_database: true,
      backup_directory: true,
    });
  };

  const handleDeleteConfirmed = async () => {
    if (!idToDelete) {
      enqueueSnackbar(null, { variant: 'error' });
      return;
    }
    await deleteBackup.mutateAsync(idToDelete);

    enqueueSnackbar(t('backup_deleted_successfully'), { variant: 'success' });
  };

  const backupNameToRestore =
    idToRestore && data?.data.result?.find(backup => backup.id === idToRestore)?.label;
  const backupNameToDelete =
    idToDelete && data?.data.result?.find(backup => backup.id === idToDelete)?.label;

  return (
    <>
      <Table
        actionColumnOverrides={{
          minWidth: '90px',
        }}
        title={t('manual_backups')}
        label={t('manual_backups')}
        columns={columns}
        data={data?.data.result || []}
        totalRowCount={data?.data.metadata?.total || 0}
        isLoading={isLoading}
        state={tableState}
        rowActions={rowActions}
        searchPlaceholder={t('search_backups')}
        enableSearch
        emptyState={
          <EmptyState
            icon={<Server />}
            title={t('no_manual_backups_yet')}
            description={t('you_dont_have_any_backups_yet')}
            action={
              <RoleGuard roles={['billing_admin']} type="block">
                <Button
                  variant="contained"
                  onClick={() => setIsCreateDialogOpen(true)}
                  startIcon={<PlusIcon />}
                >
                  {t('create_backup')}
                </Button>
              </RoleGuard>
            }
          />
        }
      >
        <RoleGuard roles={['billing_admin']} type="block">
          <Button
            variant="contained"
            onClick={() => setIsCreateDialogOpen(true)}
            startIcon={<PlusIcon />}
          >
            {t('create_backup')}
          </Button>
        </RoleGuard>
      </Table>

      {idToRestore ? (
        <ConfirmationDialog
          title={t('restore_backup')}
          description={
            <Trans
              i18nKey="restore_backup_description"
              values={{ name: backupNameToRestore }}
              components={[<strong key={0} />]}
            />
          }
          onConfirm={handleRestoreConfirmed}
          onClose={() => setIdToRestore(null)}
        />
      ) : null}
      {idToDelete ? (
        <ConfirmationDialog
          title={t('delete_backup')}
          description={
            <Trans
              i18nKey="delete_backup_description"
              values={{ name: backupNameToDelete }}
              components={[<strong key={0} />]}
            />
          }
          onConfirm={handleDeleteConfirmed}
          onClose={() => setIdToDelete(null)}
          confirmColor="error"
          confirmText={t('delete')}
        />
      ) : null}
      {isCreateDialogOpen ? (
        <ManualBackupCreateDialog siteId={siteId} onClose={() => setIsCreateDialogOpen(false)} />
      ) : null}
    </>
  );
}
