import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCreateBackup, CreateBackupRequest } from 'api/backups';
import { useForm } from 'react-hook-form';
import { CheckboxField } from 'component/new_design/base/forms/CheckboxField';
import { TextField } from 'component/new_design/base/forms/TextField';
import { FormDialog } from 'component/new_design/base/dialogs/FormDialog';

interface ManualBackupCreateDialogProps {
  readonly siteId: string;
  readonly onClose: () => unknown;
}

export const ManualBackupCreateDialog = ({ siteId, onClose }: ManualBackupCreateDialogProps) => {
  const { t } = useTranslation();

  const methods = useForm<CreateBackupRequest>({
    defaultValues: {
      label: '',
      backup_directory: false,
      backup_database: false,
    },
    resolver: values => {
      const anyChecked = values.backup_directory || values.backup_database;
      return { values, errors: anyChecked ? {} : { backup_directory: 'required' } };
    },
  });

  const createBackup = useCreateBackup(siteId);

  const onSubmit = async (values: CreateBackupRequest) => {
    await createBackup.mutateAsync({
      ...values,
      label: values.label?.trim() || t('manual_backup'),
    });
    onClose();
  };

  return (
    <FormDialog
      form={
        <Stack gap={4}>
          <Typography variant="body2">{t('create_backup_description')}</Typography>
          <TextField name="label" label={t('label')} placeholder={t('backup_label')} />
          <CheckboxField name="backup_directory" label={t('backup_files')} />
          <CheckboxField name="backup_database" label={t('backup_database')} />
        </Stack>
      }
      methods={methods}
      onSubmit={onSubmit}
      onClose={createBackup.isPending ? undefined : onClose}
      title={t('create_backup')}
      submitText={t('create')}
    />
  );
};
